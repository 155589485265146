module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"bruno-brito-portfolio","short_name":"bbportfolio","start_url":"/","background_color":"#fe8759","theme_color":"#fe8759","display":"minimal-ui","icon":"src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"31e212d51d6ada310e61e62c0e9f4ead"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1100},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
